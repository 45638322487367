import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  mainGreen,
  mainWhite,
  darkGray,
  lightGray,
  innerWidth,
  mainPink,
  screen,
} from "../common/variables";
import Logo from "../../images/svg/logo.svg";
import {
  FaRegEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import FormNewsletter from "../form-newsletter";

const Wrapper = styled.footer`
  background: ${darkGray};
  display: none;
  @media ${screen.medium} {
    display: block;
  }

  .footer-contact {
    display: flex;
    justify-content: space-evenly;
    max-width: 1280px;
    margin: 0 auto;
    padding: 50px 0;

    &__logo {
      max-width: 110px;
      width: 100%;
    }

    &__social-links {
      li {
        a {
          color: ${mainWhite};
          display: block;
          font-size: 1.1rem;
          font-weight: 300;
          margin-bottom: 15px;
          transition: color 0.19s ease-out;

          @media ${screen.withCursor} {
            &:hover {
              color: ${mainGreen};
            }
          }

          span {
            vertical-align: middle;
          }

          svg {
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
    }

    &__form {
      color: ${mainWhite};

      h4 {
        font-size: 1.1rem;
        font-weight: 300;
        text-align: center;
        margin-bottom: 15px;
      }
    }
  }

  .footer-end {
    background: ${lightGray};
    padding: 10px 0;

    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;
      @media ${screen.xsmall} {
        padding: 0 70px;
      }
      @media ${screen.large} {
        padding: 0;
      }

      .active {
        color: ${mainGreen};
      }
    }
    &__copyright {
      display: flex;
      align-items: center;

      p {
        color: ${mainWhite};
        font-size: 0.9rem;
        font-weight: 300;
        margin-right: 30px;
      }
      a {
        color: ${mainWhite};
        display: block;
        font-size: 0.9rem;
        transition: all 0.19s ease-out;
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainGreen};
          }
        }
      }
    }

    &__owner {
      color: ${mainWhite};
      font-size: 0.9rem;
      font-weight: 300;

      a {
        color: ${mainGreen};
        transition: color 0.19s ease-out;
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainPink};
          }
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <div className="footer-contact">
        <Link to="/" className="footer-contact__logo">
          <Logo />
        </Link>
        <ul className="footer-contact__social-links">
          <li>
            <a href="mailto:hello@peddl.in">
              <FaRegEnvelope />

              <span> hello@peddl.in</span>
            </a>

            <a
              href="https://www.facebook.com/peddl.in.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF /> <span>Facebook</span>
            </a>
            <a
              href="https://www.instagram.com/peddl.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram /> <span>Instagram</span>
            </a>
            <a
              href="https://twitter.com/peddl_in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter /> <span>Twitter</span>
            </a>
          </li>
        </ul>
        <div className="footer-contact__form">
          <h4>WANT TO HEAR ABOUT NEW RIDES?</h4>
          <FormNewsletter />
        </div>
      </div>
      <div className="footer-end">
        <div className="footer-end__flex">
          <div className="footer-end__copyright">
            <p>&copy; Peddl.in</p>

            <Link
              to="/privacy-policy/"
              activeClassName="active"
              title="Privacy policy page"
            >
              Privacy Policy
            </Link>
          </div>
          <p className="footer-end__owner">
            Powered by{" "}
            <a href="https://www.hellohandsome.com.au/" target="_blank">
              Handsome
            </a>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
