import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../css/normalize.css";
import "../css/global.css";
import { mainFont, siteWidth } from "../components/common/variables";
import styled from "styled-components";
import { usePosition } from "use-position";
import Nav from "../components/nav";
import Footer from "../components/footer";

const Wrapper = styled.main`
  font-family: ${mainFont};
  max-width: ${siteWidth};
  position: relative;
  margin-top: ${(props) => (props.warning ? `0px` : `0`)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
`;

const Layout = ({ children, path }) => {
  const [warning, setWarning] = useState(null);
  const [width, setWidth] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const [height, setHeight] = useState(null);

  // get current user location latitude and longitude
  const { latitude, longitude } = usePosition(true);

  // each page. props can be passed like global
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      // store props  /state here
      latitude,
      longitude,
      browserHeight: height,
      browserWidth: width,
      isNavOpen,
      warning,
    })
  );

  const isHomePage =
    typeof window !== "undefined" && window.location.pathname === "/";

  const isRidesPage =
    typeof window !== "undefined" &&
    window.location.pathname.indexOf("/rides/") !== -1;

  const isRideTemplate =
    typeof window !== "undefined" &&
    window.location.pathname.indexOf("rides") !== -1 &&
    path.length > 8;

  useEffect(() => {
    // show warning widget if user hasn't closed yet
    if (window.sessionStorage.getItem("peddlnwarning")) {
      setWarning(false);
    } else {
      setWarning(true);
    }
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    // get current browser width and height size
    const resizing = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", resizing);

    return () => {
      window.removeEventListener("resize", resizing);
    };
  }, []);

  const closeWarning = () => {
    window.sessionStorage.setItem("peddlnwarning", true);
    setWarning(false);
  };

  return (
    <Wrapper warning={warning}>
      <Nav
        // warning to show/remove widget
        // being used inside Wrapper styled component
        warning={warning}
        closeWarning={closeWarning}
        isHomePage={isHomePage}
        isRidePage={isRidesPage}
        isRideTemplate={isRideTemplate}
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
      />
      {childrenWithProps}
      <Footer />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
