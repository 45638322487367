import React, { useState } from "react";
import styled from "styled-components";
import {
  emailRegex,
  darkGray,
  mainPink,
  mainGreen,
  mainWhite,
  screen,
} from "../common/variables";
import Arrow from "../../images/svg/arrow.svg";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Wrapper = styled.div`
  #lg-newsletter-form {
    display: none;
    position: relative;

    @media ${screen.medium} {
      display: block;
    }
    p {
      display: flex;

      input {
        border: 0;
        color: ${darkGray};
        width: 280px;
        outline-color: ${mainPink};
        padding: 16px 30px;
        border-radius: 25px 0 0 25px;
        font-size: 0.9rem;
        ::placeholder {
          color: ${darkGray};
          font-weight: 300;
        }
      }

      button {
        background: ${mainGreen};
        border: 0;
        border-radius: 0 25px 25px 0;
        cursor: pointer;
        color: ${darkGray};
        font-size: 0.9rem;
        outline-color: ${mainPink};
        padding: 8px 15px;
        transition: all 0.19s ease-out;
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainPink};
            color: ${mainWhite};
          }
        }
      }
    }

    /* &::after {
      content: "wew";
      width: 100%;
      height: 100%;
      background: red;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    } */

    .submit-feedback {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      bottom: -32px;
    }
  }

  #sm-newsletter-form {
    @media ${screen.medium} {
      display: none;
    }
    input {
      box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
      width: 100%;
      font-size: 16px;
      text-align: center;
      outline-color: ${mainPink};
      padding: 18px 0 16px 0;
      border-radius: 30px;
      border: 0;
      background: ${mainWhite};
      margin: 16px 0;
      display: block;
      @media ${screen.xsmall} {
        font-size: 1.25rem;
        width: 338px;
        margin: 16px auto;
      }

      ::placeholder {
        color: #c1c1c1;
      }
    }

    button {
      border: 0;
      background: 0;
      border-radius: none;
      display: flex;
      margin: 0 auto;
      position: relative;
      max-width: 120px;
      cursor: pointer;
      align-items: center;

      span {
        color: ${darkGray};
        display: block;
        margin-right: 10px;
        font-size: 0.87rem;
        font-weight: 600;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
        }
      }

      svg {
        display: block;
        width: 20px;
        height: 20px;

        .arrow-fill {
          fill: ${darkGray};
        }
      }
    }

    .submit-feedback {
      margin-top: 12px;
      display: block;
    }
  }
`;

const FormNewsletter = () => {
  const [email, setEmail] = useState("");
  const [submitFeedback, setSubmitFeedback] = useState("");

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setSubmitFeedback("Invalid email address");
    } else {
      setEmail("");
      try {
        const result = await addToMailchimp(email);
        const messageFeedback = result.msg.toLowerCase().split(" ");

        if (
          messageFeedback.includes(email.toLowerCase()) ||
          messageFeedback.includes(`"${email.toLowerCase()}"`)
        ) {
          setSubmitFeedback("This email is already subscribed!");
        } else {
          setSubmitFeedback("Thanks for subscribing!");
        }
      } catch (e) {
        console.log("error: ", e);
        setSubmitFeedback("Error. Please try again later!");
      }
    }
  };

  return (
    <Wrapper>
      <form
        id="lg-newsletter-form"
        onSubmit={handleSubmit}
        onFocus={() => setSubmitFeedback("")}
      >
        <p>
          <input
            placeholder="ENTER YOUR EMAIL ADDRESS"
            aria-label="Email"
            type="text"
            onChange={handleChange}
            value={email}
          />
          <button type="submit">SEND</button>
        </p>

        {submitFeedback && (
          <span
            className="submit-feedback"
            style={{
              color:
                submitFeedback.indexOf("Invalid") === -1
                  ? mainGreen
                  : "crimson",
            }}
          >
            {submitFeedback}
          </span>
        )}
      </form>

      <form
        id="sm-newsletter-form"
        onSubmit={handleSubmit}
        onFocus={() => setSubmitFeedback("")}
      >
        <input
          placeholder="YOUR EMAIL ADDRESS"
          aria-label="Email"
          onChange={handleChange}
          value={email}
          type="text"
        />
        <button type="submit">
          <span>SUBMIT</span> <Arrow />
        </button>

        {submitFeedback && (
          <span
            className="submit-feedback"
            style={{
              color:
                submitFeedback.indexOf("Invalid") === -1 ? mainPink : "crimson",
            }}
          >
            {submitFeedback}
          </span>
        )}
      </form>
    </Wrapper>
  );
};

export default FormNewsletter;
