import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  mainGreen,
  mainWhite,
  mainPink,
  darkGray,
  screen,
} from "../common/variables";
import Arrow from "../../images/svg/arrow.svg";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import FormNewsletter from "../form-newsletter";

const Wrapper = styled(motion.div)`
  background: ${mainGreen};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 460px;
  z-index: 10;
  padding-top: 110px;
  border-radius: 0 75px 0 0;
  overflow-y: scroll;
  @media ${screen.xsmall} {
    padding-top: 140px;
  }
  @media ${screen.medium} {
    display: none;
  }

  .nav-list {
    &__each {
      a {
        background: ${mainWhite};
        color: ${mainPink};
        font-weight: 600;
        position: relative;
        margin: 0 35px;
        display: block;
        padding: 17px 25px;
        margin-bottom: 20px;
        border-radius: 30px;
        box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
        font-size: 0.93rem;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
          padding: 20px 35px 18px 35px;
          max-width: 338px;
          margin: 0 auto 20px auto;
        }

        svg {
          max-width: 27px;
          height: 27px;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .active {
      }
    }
  }

  .social-list {
    display: flex;
    justify-content: space-evenly;
    max-width: 290px;
    margin: 40px auto 0 auto;
    @media ${screen.xsmall} {
      margin: 50px auto 0 auto;
    }

    &__each {
      a {
        font-size: 1.85rem;
        display: flex;
        background: ${mainWhite};
        color: ${mainGreen};
        border-radius: 50%;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        @media ${screen.xsmall} {
          width: 58px;
          height: 58px;
          font-size: 2.1rem;
        }

        svg {
          display: block;
          margin: auto;
        }
      }
    }
  }

  .menu-footer {
    margin: 40px 0 0 0;
    @media ${screen.small} {
      margin: 80px 0 0 0;
    }

    &__subscribe-form {
      background: #d9e556;
      text-align: center;
      padding: 30px 35px;
      @media ${screen.xsmall} {
        padding: 30px 0;
      }

      @media ${screen.small} {
        margin: 50px 0 0 0;
      }

      h4 {
        color: ${darkGray};
        font-size: 1rem;
        font-weight: 600;
        max-width: 200px;
        margin: 0 auto;
        line-height: 1.25;
        @media ${screen.xsmall} {
          font-size: 1.3rem;
          max-width: 250px;
        }
      }
    }

    &__end {
      background: ${mainGreen};
      padding: 22px 0;

      .copyright {
        display: flex;
        color: ${mainWhite};
        align-items: center;
        justify-content: center;
        font-size: 0.82rem;

        p {
          font-size: 0.82rem;
        }

        span {
          margin: 0 7px;
        }
        a {
          color: ${mainWhite};
        }
      }

      .owner {
        color: ${mainWhite};
        text-align: center;
        margin-top: 4px;
        font-size: 1.02rem;

        a {
          color: ${mainWhite};
          font-weight: 700;
        }
      }
    }
  }
`;

// motion framer animation props value
const variantsContainer = {
  open: { x: 0, pointerEvents: "all" },
  close: { x: "-100%", pointerEvents: "none" },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuOverlay = ({ isNavOpen, toggleNav, swipeHandler }) => {
  return (
    <Wrapper
      initial="close"
      animate={isNavOpen ? "open" : "close"}
      variants={variantsContainer}
      transition={{ duration: 0.28 }}
    >
      <motion.div variants={variantsList}>
        <ul className="nav-list" {...swipeHandler}>
          <motion.li variants={variantsItem} className="nav-list__each">
            <Link
              activeClassName="active"
              to="/"
              onClick={toggleNav}
              title="Home page"
            >
              HOME <Arrow />
            </Link>
          </motion.li>
          <motion.li variants={variantsItem} className="nav-list__each">
            <Link
              activeClassName="active"
              to="/about/"
              onClick={toggleNav}
              title="About page"
            >
              ABOUT <Arrow />
            </Link>
          </motion.li>
          <motion.li variants={variantsItem} className="nav-list__each">
            <Link
              activeClassName="active"
              to="/rides/"
              onClick={toggleNav}
              title="All rides"
            >
              RIDES <Arrow />
            </Link>
          </motion.li>
        </ul>
        <motion.ul variants={variantsItem} className="social-list">
          <li className="social-list__each">
            <a
              href="https://www.facebook.com/peddl.in.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li className="social-list__each">
            <a
              href="https://www.instagram.com/peddl.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li className="social-list__each">
            <a
              href="https://twitter.com/peddl_in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </li>
        </motion.ul>

        <div className="menu-footer">
          <motion.div
            variants={variantsItem}
            className="menu-footer__subscribe-form"
          >
            <h4>WANT TO HEAR ABOUT THE LATEST RIDES?</h4>
            <FormNewsletter />
          </motion.div>

          <motion.div variants={variantsItem} className="menu-footer__end">
            <div className="copyright">
              <p>&copy; Peddl.in</p> <span>|</span>
              <p>
                <Link to="/privacy-policy/" title="Privacy page">
                  Privacy
                </Link>
              </p>
            </div>
            <p className="owner">
              Powered by{" "}
              <a href="https://www.hellohandsome.com.au" target="_blank">
                Handsome
              </a>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </Wrapper>
  );
};

export default MenuOverlay;
