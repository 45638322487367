import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import {
  mainGreen,
  mainWhite,
  darkGray,
  mainPink,
  screen,
  innerWidth,
  siteWidth,
} from "../common/variables";
import { useSwipeable } from "react-swipeable";
import { ScrollLocky } from "react-scroll-locky";
import MenuOverlay from "./menu-overlay";
import HamburgerIcon from "./hamburger-icon";
import Logo from "../../images/svg/logo.svg";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import IconClose from "../../images/svg/close-icon2.svg";

const Wrapper = styled.nav`
  display: ${(props) => (props.isRideTemplate ? "none" : "block")};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
  max-width: ${siteWidth};
  left: 50%;
  transform: translateX(-50%);
  @media ${screen.xsmall} {
    display: block;
  }

  #warning {
    background: ${mainWhite};
    position: relative;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    margin-bottom: 20px;
    height: 48px;

    p {
      color: ${mainPink};
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      line-height: 1.38;
      @media ${screen.xsmall} {
        font-size: 0.95em;
      }
      @media ${screen.small} {
        font-size: 1rem;
      }

      .break {
        display: block;
        @media ${screen.xsmall} {
          display: inline;
        }
      }
    }

    .close-btn {
      color: red;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;
      svg {
        width: 12px;
        height: 12px;
        @media ${screen.small} {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  #navbar-lg {
    background: ${mainGreen};
    box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.2);
    display: none;
    @media ${screen.medium} {
      display: block;
      margin-top: ${(props) => (props.warning ? "0px" : "0")};
    }

    .nav-flex {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
      @media ${screen.xsmall} {
        padding: 15px 70px;
      }
      @media ${screen.large} {
        padding: 15px 0;
      }

      &__logo {
        svg {
          max-width: 120px;
          width: 100%;
          display: block;
          margin-right: 100px;
        }
      }

      &__page-links {
        display: flex;
        align-items: center;

        li {
          a {
            color: ${mainWhite};
            font-size: 1rem;
            font-weight: 500;
            display: block;
            margin-right: 50px;
            transition: all 0.2s ease-out;
            &:hover {
              color: ${darkGray};
            }
          }

          .active {
            color: ${darkGray};
          }
        }
      }

      &__social-links {
        display: flex;
        align-items: center;
        li {
          a {
            font-size: 1.25rem;
            display: flex;
            background: ${mainWhite};
            color: ${mainGreen};
            border-radius: 50%;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            margin-left: 25px;
            transition: all 0.19s ease-in-out;
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainGreen};
                color: ${mainWhite};
              }
            }

            svg {
              display: block;
              margin: auto;
            }
          }
        }
      }
    }
  }

  #navbar-sm {
    display: ${(props) => (props.isRidesPage ? "none" : "block")};
    background: ${(props) => (props.isHomePage ? `none` : mainPink)};
    display: block;
    height: 84px;
    position: fixed;
    width: 100%;
    top: ${(props) => (props.warning ? "0px" : "0")};
    @media ${screen.xsmall} {
      display: block;
      height: 83px;
    }
    @media ${screen.medium} {
      display: none;
    }

    .nav-flex {
      &__logo {
        position: absolute;
        top: calc(50% + 4px);
        left: 50%;
        transform: translate(-50%, -50%);
        @media ${screen.xsmall} {
          right: 55px;
          left: auto;
          transform: translate(0, -50%);
        }
        @media ${screen.small} {
          right: 65px;
        }

        svg {
          max-width: 110px;
          width: 100%;
        }
      }
    }
  }
`;

// backgroud on opened nav menu
const DarkOverlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  opacity: ${(props) => (props.isNavOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isNavOpen ? "all" : "none")};
  transition: opacity 0.28s ease-out;
  @media ${screen.xsmall} {
    background: rgba(0, 0, 0, 0.9);
  }
  @media ${screen.medium} {
    display: none;
  }
`;

const Nav = ({
  warning,
  closeWarning,
  isHomePage,
  isRidesPage,
  isRideTemplate,
  isNavOpen,
  setIsNavOpen,
}) => {
  // open/close nav menu

  // swipe event handler
  const swipeHandler = useSwipeable({
    onSwipedLeft: (data) => {
      // navigate to different page if user has swipe left on link element
      if (data.event.target.pathname) {
        setIsNavOpen(false);
        navigate(data.event.target.pathname);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // event listener to close the nav menu overlay if user has clicked outside the nav menu
  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "dark-overlay") setIsNavOpen(false);
    };

    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);
  return (
    <>
      <Wrapper
        warning={warning}
        isRideTemplate={isRideTemplate}
        isHomePage={isHomePage}
        isRidesPage={true}
      >
        {/* {warning && (
          <div id="warning">
            <p>
              COVID-19: Remember to practice social
              <span className="break"> distancing while you're peddl.in!</span>
            </p>
            <span className="close-btn" onClick={closeWarning}>
              <IconClose />
            </span>
          </div>
        )} */}

        <div id="navbar-lg">
          <div className="nav-flex">
            <ul className="nav-flex__page-links">
              <li>
                <Link className="nav-flex__logo" to="/">
                  <Logo />
                </Link>
              </li>
              <li>
                <Link to="/" activeClassName="active" title="Home page">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about/" activeClassName="active" title="About page">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/rides/" activeClassName="active" title="All rides">
                  RIDES
                </Link>
              </li>
            </ul>

            <ul className="nav-flex__social-links">
              <li>
                <a
                  href="https://www.facebook.com/peddl.in.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/peddl.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/peddl_in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="navbar-sm">
          <div className="nav-flex">
            <Link className="nav-flex__logo" to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </Wrapper>
      <ScrollLocky enabled={isNavOpen} isolation={false}>
        <MenuOverlay
          toggleNav={toggleNav}
          isNavOpen={isNavOpen}
          swipeHandler={swipeHandler}
        />
      </ScrollLocky>

      <DarkOverlay isNavOpen={isNavOpen} id="dark-overlay" />
      <HamburgerIcon
        warning={warning}
        isNavOpen={isNavOpen}
        toggleNav={toggleNav}
        isRideTemplate={isRideTemplate}
      />
    </>
  );
};

export default Nav;
