import React from "react";
import styled from "styled-components";
import { screen, siteWidth } from "../common/variables";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  max-width: ${siteWidth};
  left: 0;
  height: 84px;
  position: fixed;
  width: 100%;
  max-width: 120px;
  top: ${props => (props.warning && !props.isNavOpen ? "48px" : "0")};
  display: ${props => (props.isRideTemplate ? "none" : "block")};
  @media ${screen.xsmall} {
    display: block;
  }
  @media ${screen.medium} {
    display: none;
  }

  #nav-icon {
    width: 24px;
    height: 20px;
    position: absolute;
    left: 30px;
    top: 50%;
    cursor: pointer;
    z-index: 10;
    transform: rotate(0deg) translateY(-50%);
    transition: 0.24s ease;
    cursor: pointer;
    @media ${screen.xsmall} {
      left: 70px;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #fff;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.24s ease;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 8px;
      }
      &:nth-child(3) {
        top: 16px;
      }
    }
  }

  #nav-icon.open span:nth-child(1) {
    top: 8px;
    transform: rotate(135deg);
  }

  #nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -24px;
  }

  #nav-icon.open span:nth-child(3) {
    top: 8px;
    transform: rotate(-135deg);
  }
`;

const HamburgerIcon = ({ warning, toggleNav, isNavOpen, isRideTemplate }) => {
  return (
    <Wrapper
      warning={warning}
      isRideTemplate={isRideTemplate}
      isNavOpen={isNavOpen}
    >
      <div
        onClick={toggleNav}
        id="nav-icon"
        className={isNavOpen ? "open" : null}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Wrapper>
  );
};

export default HamburgerIcon;
