// colours used
export const mainGreen = `#C9D727`;
export const darkGray = `#313532`;
export const lightGray = `#505251`;
export const mainBlack = `#231F20`;
export const mainWhite = `#FFFFFF`;
export const dirtyWhite = `#F5F5F5`;
export const mainPink = `#EA5482`;

// website width container
export const siteWidth = "1920px";
export const innerWidth = "1450px";

// regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// fonts
export const mainFont = `proxima-nova, sans-serif`;
export const subFont = `proxima-nova-condensed, sans-serif`;

export const transHover = `all 0.18s ease-out`;

// Media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 960px)`, // → tablet horizontal
  medium: `(min-width: 1240px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
};
